import { formatUsDate, removeMinYear } from '../../../utils/functions'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import { Item, ToolTypes } from '../../../types'
import { Settings } from '../../../hooks/useSettings/useSettings.type'

export const exportToExcel = async (excelData: any[]) => {
   const fileExtension = '.xlsx'
   const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8'
   const fileName = `cart-export-${formatUsDate(new Date())}${fileExtension}`
   const worksheet = XLSX.utils.json_to_sheet(excelData)
   const numHeaders = Object.keys(excelData[0]).length
   worksheet['!cols'] = Array(numHeaders).fill({ wch: 20 })
   const headerKeys = Object.keys(worksheet).filter((key) =>
      /^[A-Z]+[1]$/.test(key)
   )

   headerKeys.map(
      (key) =>
         (worksheet[key].s = {
            fill: {
               fgColor: { rgb: 'FFD1D7DB' },
            },
            alignment: {
               vertical: 'center',
               horizontal: 'center',
               wrapText: '1',
            },
            border: {
               right: {
                  style: 'thick',
                  color: '96999B',
               },
               left: {
                  style: 'thick',
                  color: '96999B',
               },
               top: {
                  style: 'thick',
                  color: '96999B',
               },
               bottom: {
                  style: 'thick',
                  color: '96999B',
               },
            },
         })
   )

   const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] }
   const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
   const data = new Blob([excelBuffer], { type: fileType })
   FileSaver.saveAs(data, fileName)
}

export const generateExcelData = (items: Item[], settings: Settings) => {
   const excelData = items.map((item) => {
      return {
         'Item Name': item.Title,
         Type: ToolTypes[item.ToolType],
         'Assigned to Kit': item.AssignedToKitName,
         'Total Qty': item.Quantity,
         'Reorder Level': item.ReorderLevelQuantity,
         'Status Qty': item.StatusQuantity,
         Status: item.StatusDesc,
         'Loaned/Pending To': item.AssignedToUser,
         'Item Detail': item.Description,
         Manufacturer: item.Manufacturer,
         'Model No.': item.ModelNumber,
         'Serial No.': item.SerialNumber,
         Barcode: item.Barcode,
         Category: item.Category,
         'Purchase Date': removeMinYear(formatUsDate(item.PurchaseDate)),
         'Purchase Price': item.PurchasePrice,
         'Total Value': item.TotalValue,
         'Warranty Date': removeMinYear(formatUsDate(item.WarrantyDate)),
         'Rental Item': item.RentalDate ? 'rented' : 'not rented',
         'Rental Start Date': removeMinYear(formatUsDate(item.RentalDate)),
         'Rental End Date': removeMinYear(formatUsDate(item.RentalReturnDate)),
         [settings.CustomRentalRate1Label]: settings.CustomRentalRate1Label
            ? item.CustomRentalRate1
            : '-',
         [settings.CustomRentalRate2Label]: settings.CustomRentalRate2Label
            ? item.CustomRentalRate2
            : '-',
         [settings.CustomRentalRate3Label]: settings.CustomRentalRate3Label
            ? item.CustomRentalRate3
            : '-',
         [settings.CustomRentalRate4Label]: settings.CustomRentalRate4Label
            ? item.CustomRentalRate4
            : '-',
         [settings.CustomDate1Label]: settings.CustomDate1Label
            ? removeMinYear(formatUsDate(item.CustomDate1Value))
            : '-',
         [settings.CustomDate2Label]: settings.CustomDate2Label
            ? removeMinYear(formatUsDate(item.CustomDate2Value))
            : '-',
         [settings.CustomText1Label]: settings.CustomText1Label
            ? item.CustomText1Value
            : '-',
         [settings.CustomText2Label]: settings.CustomText2Label
            ? item.CustomText2Value
            : '-',
         [settings.CustomURL1Label]: settings.CustomURL1Label
            ? item.CustomURL1Value
            : '-',
         [settings.CustomURL2Label]: settings.CustomURL2Label
            ? item.CustomURL2Value
            : '-',
         'WebSite URL': item.WebSiteURL,
         'Owners URL': item.AttachmentUrl,
         'Return Warehouse': item.DefaultLocation,
         'Current/Pending Location': item.CurrentLocation,
         'Last Audit Date': removeMinYear(formatUsDate(item.LastAuditDate)),
         'Last Audit Condition': item.LastAuditConditionDesc,
         Vendor: item.Vendor,
         'Created Date': removeMinYear(formatUsDate(item.CreatedDate)),
      }
   })

   return excelData
}
