import { useState } from 'react'
import { useMutation } from 'react-query'
import { AxiosError } from 'axios'

import { useAPI } from '..'
import { isBrowser } from '../../utils/functions'
import { PATH } from '../../constants/global'

import { LoginCredentials, UseLogin, LoginErrorResponse } from './useLogin.type'

export default function (disableSession?: boolean): UseLogin {
   const [token, setToken] = useState('')
   const request = useAPI()
   const [error, setError] = useState('')
   const { isLoading, isSuccess, mutate } = useMutation(
      (credentials: LoginCredentials) => {
         return request.post(PATH.AUTH.LOGIN, {
            username: credentials.email,
            password: credentials.password,
         })
      },
      {
         onSuccess: (data) => {
            setToken(data?.data?.access_token)

            if (isBrowser() && !disableSession) {
               window?.localStorage?.setItem(
                  'ShareMyToolboxUser',
                  JSON.stringify(data?.data)
               )
            }
         },
         onError: (error: AxiosError<LoginErrorResponse>) => {
            const { error_description } = error?.response?.data

            setError(
               error_description ||
                  'Your username or password were not recognised'
            )
         },
      }
   )

   const login = (email: string, password: string) => {
      mutate({ email, password })
   }

   const { mutate: getNewToken } = useMutation(
      (refreshToken: string) => {
         return request.post(PATH.AUTH.REFRESH_TOKEN, {
            refresh_token: refreshToken,
         })
      },
      {
         onSuccess: (data) => {
            if (isBrowser() && !disableSession) {
               window?.localStorage?.setItem(
                  'ShareMyToolboxUser',
                  JSON.stringify(data?.data)
               )
            }
         },
         onError: (error: AxiosError<LoginErrorResponse>) => {
            console.error(error)
            // TODO HANDLE ERROR
         },
      }
   )

   const generateNewToken = (refreshToken: string) => {
      getNewToken(refreshToken)
   }

   return {
      error,
      isLoading,
      isSuccess,
      login,
      token,
      generateNewToken,
   }
}
