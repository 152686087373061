import React, { useCallback, useMemo } from 'react'

import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { IoIosArrowForward } from 'react-icons/io'
import classNames from 'classnames'

import * as styles from './navItem.module.scss'
import { isChildOfItem } from '../../../../utils/navigation'
import Svg from '../../svg/svg'
import { NavItemProps } from './types'
import TasksNavItem from './tasksNavItem'

export const NavItem = ({
   children,
   className,
   currentNavItem,
   external,
   icon,
   iconSize,
   isOpen = false,
   isPrimary,
   label,
   route,
   onToggle,
}: NavItemProps) => {
   const { pathname } = useLocation()

   const hasChildren = useMemo(() => !!children?.length, [children])

   const currentItemHasSelectedChild = useMemo(
      () => isChildOfItem(children, pathname),
      [pathname, children]
   )

   const handleToggleClick = useCallback(() => onToggle(), [onToggle])

   const iconElement = useMemo(
      () =>
         isPrimary && icon ? (
            <span className={styles.navItemIcon}>
               <Svg
                  id={icon}
                  height={iconSize}
                  width={icon === 'imageMatch' ? 26 : undefined}
               />
            </span>
         ) : (
            <></>
         ),
      [isPrimary, icon]
   )

   const link = useMemo(() => {
      if (label === 'Tasks') {
         return (
            <TasksNavItem label={label} path={route.path} icon={iconElement} />
         )
      }

      if (external) {
         return (
            <a className={styles.navItemLink} href={route.path} target="_blank">
               {iconElement}
               {label}
            </a>
         )
      }

      return (
         <Link className={styles.navItemLink} to={route.path}>
            {iconElement}
            {label}
         </Link>
      )
   }, [label, external])

   const toggleButton = useMemo(
      () => (
         <button className={styles.navItemLink} onClick={handleToggleClick}>
            {iconElement}
            <span>{label}</span>
            <span
               className={classNames(styles.navItemArrow, {
                  [styles.navItemArrowActive]:
                     currentItemHasSelectedChild || isOpen,
               })}
            >
               <IoIosArrowForward />
            </span>
         </button>
      ),
      [label, currentItemHasSelectedChild, isOpen]
   )

   return (
      <li
         className={classNames(className, styles.navItem, {
            [styles.navItemPrimary]: isPrimary,
            [styles.isActive]: currentItemHasSelectedChild || isOpen,
         })}
      >
         {hasChildren ? toggleButton : link}

         {hasChildren && (
            <ul hidden={!isOpen}>
               {children.map((child) => (
                  <NavItem
                     key={`${child.route.path}-${child.label}`}
                     {...child}
                     className={styles.navItemChild}
                     currentNavItem={currentNavItem}
                  />
               ))}
            </ul>
         )}
      </li>
   )
}
