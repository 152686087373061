// extracted by mini-css-extract-plugin
export var aiIcon = "importItemsModal-module--aiIcon--4f2c3";
export var bottomImage = "importItemsModal-module--bottomImage--0a2a7";
export var bottomSection = "importItemsModal-module--bottomSection--f7c30";
export var buttonsWrapper = "importItemsModal-module--buttonsWrapper--8d2e1";
export var closeWindowInfo = "importItemsModal-module--closeWindowInfo--f474a";
export var column = "importItemsModal-module--column--772e6";
export var content = "importItemsModal-module--content--8a03f";
export var contentWrapper = "importItemsModal-module--contentWrapper--4bfa3";
export var description = "importItemsModal-module--description--67177";
export var errorContent = "importItemsModal-module--errorContent--c88ad";
export var greenTriangle = "importItemsModal-module--greenTriangle--d6132";
export var heading = "importItemsModal-module--heading--40e16";
export var helpLinkWrapper = "importItemsModal-module--helpLinkWrapper--43f5f";
export var hideButton = "importItemsModal-module--hideButton--f9f9d";
export var importResults = "importItemsModal-module--importResults--5acd1";
export var leftColumn = "importItemsModal-module--leftColumn--56c7b";
export var middleImage = "importItemsModal-module--middleImage--50744";
export var numberedSection = "importItemsModal-module--numberedSection--79afb";
export var progressIndicatorWrapperCenter = "importItemsModal-module--progressIndicatorWrapperCenter--99794";
export var progressIndicatorWrapperLeft = "importItemsModal-module--progressIndicatorWrapperLeft--08415";
export var rightColumn = "importItemsModal-module--rightColumn--94aa7";
export var topImage = "importItemsModal-module--topImage--1b95d";
export var yellowCircle = "importItemsModal-module--yellowCircle--afc77";