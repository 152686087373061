import { isCompanyRole, isEmployeeRole } from './roles'
import { NavItems } from '../hooks/useNavigation/useNavigation.type'
import { isStarterPlan } from './planTypes'
import { Profile } from '../hooks/useProfile/useProfile.type'
import {
   navItemsForExpiredEnterprise,
   navItemsForCompanyStarter,
} from './access'

const showNavItemByFlag = (itemFlags: string[] = [], settings = {}) => {
   // nav item is visible as it has no flags
   if (!itemFlags?.length) return true

   return itemFlags?.some((flag) =>
      Object.entries(settings).some(
         ([settingName, settingEnabled]) =>
            settingName === flag && settingEnabled === true
      )
   )
}

const showNavItemByRole = (
   itemRoles: number[] = [],
   userRoles: number[] = []
) => {
   if (!userRoles?.length) return false

   if (!itemRoles?.length) return true

   return itemRoles.some((itemRole) => userRoles.includes(itemRole))
}

// this was removed for the actions page stories may reinstate multiple nav routes
// function switchActionsStartingRouteBasedOnRole(
//     navList: NavItems,
//     userRoles: number[]
//     ): void {
//         const actions = navList.find(x => x.label === 'Actions');
//         const isEmployeeUser = isEmployeeRole(userRoles);
//         if (isEmployeeUser) {
//             if (actions) actions.route = ROUTES.actions_borrow;
//         } else if (!isEmployeeUser) {
//             if (actions) actions.route = ROUTES.actions_loan;
//         }
// }

const removeNavChildrenForEmployeeRole = (
   navList: NavItems,
   userRoles: number[],
   childNavsToRemove: string[]
) => {
   const items = navList.find((x) => childNavsToRemove.includes(x.label))
   const isEmployeeUser = isEmployeeRole(userRoles)
   if (isEmployeeUser && items) {
      items.children = null
   }
}

const getPermittedNavItems = (
   items: NavItems = [],
   settings: object,
   profile: Profile
): NavItems => {
   const { EnterpriseUser: isValidSubscription, Roles } = profile
   const userRoles = Roles ?? []
   const isStarter = isStarterPlan(profile)
   const isCompany = isCompanyRole(userRoles)

   // access for starter accounts
   if (isStarter) {
      // company can access subscriptions only
      return isCompany
         ? items.filter((item) =>
              navItemsForCompanyStarter.map(String).includes(item.route.path)
           )
         : []
   }

   // access for expired enterprise accounts
   if (!isValidSubscription && isCompany) {
      return items.filter((item) =>
         navItemsForExpiredEnterprise.map(String).includes(item.route.path)
      )
   }

   // this was removed for the actions page stories may reinstate multiple nav routes
   // switch actions starting route from loan to borrow
   // switchActionsStartingRouteBasedOnRole(items, userRoles);

   return items.reduce<NavItems>((navList, navItem) => {
      // if item needs a flag to be shown and it's not available, we return early to
      // avoid unnecessary role logic being run
      const isItemVisibleByFlag = showNavItemByFlag(
         navItem?.route?.flags || [],
         settings
      )
      if (!isItemVisibleByFlag) {
         return navList
      }

      // we got this far, so the nav item is flagged to be shown, let's check if the user
      // has the correct role to view it
      const isItemVisibleByRole = showNavItemByRole(
         navItem?.route?.roles || [],
         userRoles || []
      )
      if (!isItemVisibleByRole) {
         return navList
      }

      // if current nav item doesn't have any children we can just return it
      if (!navItem?.children?.length) {
         return [...navList, navItem]
      }

      // the employee role does not need child nav items for items tab
      removeNavChildrenForEmployeeRole(navList, userRoles, ['Items'])

      return [
         ...navList,
         {
            ...navItem,
            children: getPermittedNavItems(navItem.children, settings, profile),
         },
      ]
   }, [])
}

const isChildOfItem = (items: NavItems, currentItemHref: string) => {
   return (
      !!items?.length &&
      !!items.find((child) => child.route.path === currentItemHref)
   )
}

const getAbsolutePath = () => {
   if (typeof window !== 'undefined' && window.location) {
      return window.location.origin
   }
   return ''
}

export { getPermittedNavItems, isChildOfItem, getAbsolutePath }
