import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import GoogleMapReact, { ChangeEventValue } from 'google-map-react'
import classNames from 'classnames'

export { default as MapPin } from '../mapPin/mapPin'
import {
   defaultMapOptions,
   executeReverseGeocodeLookup,
   maximumMapZoom,
   minimumMapZoom,
} from './helpers'

import * as styles from './map.module.scss'
import { MapProps, MapCoordinates } from './types'

const { GMAP_API_KEY } = process.env

const Map = ({
   className,
   children,
   mapOptions = {},
   markers = [],
   selectedLocation,
   retrieveGeoLookupResults,
   handleCloseInfoCard,
   handleOpenInfoCard,
   fitToBounds = true,
   setIsMapZooming,
}: MapProps) => {
   const [center, setCenter] = useState<MapCoordinates>({
      lat: 34.0522,
      lng: -118.2437,
   })
   const [defaultCenter, setDefaultCenter] = useState<MapCoordinates | null>(
      null
   ) // a copy to return to when zooming out function
   const [{ map, maps }, setMaps] = useState<any>({})

   const getMapBounds = (map, maps, markers) => {
      if (!maps || !map) {
         return
      }

      const bounds = new maps.LatLngBounds()

      markers.forEach((marker) => {
         bounds.extend(new maps.LatLng(marker.lat, marker.lng))
      })
      return bounds
   }

   const bindResizeListener = (map, maps, bounds) => {
      window.addEventListener(
         'idle',
         () => {
            window.addEventListener('resize', () => {
               map.fitBounds(bounds)
               if (handleCloseInfoCard) {
                  handleCloseInfoCard()
               }
            })
         },
         { once: true }
      )
   }

   const handleGoogleMapLoaded = () => {
      if (!markers.length || !map) return

      // Get bounds by our places
      const bounds = getMapBounds(map, maps, markers)

      // Fit map to bounds
      if (fitToBounds) {
         map?.fitBounds(bounds)
      }
      // Set new center
      const newCenter = bounds.getCenter()
      setCenter({ lat: newCenter.lat(), lng: newCenter.lng() })
      if (!defaultCenter) {
         /// set only once
         setDefaultCenter({ lat: newCenter.lat(), lng: newCenter.lng() })
      }
      // Bind the resize listener
      bindResizeListener(map, maps, bounds)
   }

   const handleMapChange = (event: ChangeEventValue) => {
      const { lat, lng } = event.center

      setCenter({ lat, lng })
   }

   useEffect(() => {
      handleGoogleMapLoaded()
   }, [markers, map, maps])

   // used to zoom to location and display info card
   useEffect(() => {
      if (selectedLocation) {
         executeReverseGeocodeLookup(
            { lat: selectedLocation.Latitude, lng: selectedLocation.Longitude },
            retrieveGeoLookupResults
         )

         // start zoom to location
         handleGoogleMapLoaded()
         setCenter({
            lat: selectedLocation.Latitude,
            lng: selectedLocation.Longitude,
         })
         let zoomId = setInterval(() => zoomFunction(zoomId, map), 200)
      } else if (!selectedLocation && map && map.getZoom() >= minimumMapZoom) {
         // TODO FIND A BETTER CONDITION THAN THIS
         setCenter(defaultCenter)
         let zoomOutId = setInterval(() => zoomOutFunction(zoomOutId, map), 200)
      }
   }, [selectedLocation])

   function zoomFunction(intId, map) {
      const mapZoom = map.getZoom()
      if (mapZoom >= maximumMapZoom) {
         clearInterval(intId)
         handleOpenInfoCard()
         setIsMapZooming(false)
      }
      if (mapZoom < maximumMapZoom) {
         map.setZoom(mapZoom + 0.5)
         setIsMapZooming(true)
      }
   }

   function zoomOutFunction(intId, map) {
      const mapZoom = map.getZoom()
      if (mapZoom === minimumMapZoom) {
         clearInterval(intId)
         setIsMapZooming(false)
      }
      if (mapZoom > minimumMapZoom) {
         map.setZoom(mapZoom - 1)
         setIsMapZooming(true)
      }
   }

   const urlKeys = useMemo(() => ({ key: GMAP_API_KEY }), [GMAP_API_KEY])

   const options = useMemo(
      () => ({ ...defaultMapOptions, ...mapOptions }),
      [defaultMapOptions, mapOptions]
   )

   const onGoogleApiLoaded = useCallback(
      ({ map, maps }) => setMaps({ map, maps }),
      [map, maps]
   )

   return (
      <div className={classNames(styles.wrapper, className)}>
         <GoogleMapReact
            bootstrapURLKeys={urlKeys}
            zoom={minimumMapZoom}
            center={center}
            options={options}
            onGoogleApiLoaded={onGoogleApiLoaded}
            onChange={handleMapChange}
            yesIWantToUseGoogleMapApiInternals
         >
            {children}
         </GoogleMapReact>
      </div>
   )
}

export default memo(Map)
