import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import { useEffect, useState } from 'react'

export default (): UseNews => {
   const request = useAPI()
   const [newsArticles, setNewsArticles] = useState<NewsArticleDetails[]>([])

   const { data, isLoading, isSuccess, refetch, isRefetching } = useQuery(
      'news',
      getNewsArticlesFromAPI
   )

   async function getNewsArticlesFromAPI(): Promise<
      AxiosResponse<NewsResponse>
   > {
      return await request.get(PATH.NEWS.LIST_ARTICLES)
   }

   useEffect(() => {
      refetch()
   }, [])

   useEffect(() => {
      if (data && isSuccess) {
         const getImageFromData = (htmlString?: string) => {
            const parser = new DOMParser()
            const doc = parser.parseFromString(htmlString, 'text/html')
            return doc.querySelector('img')?.src
         }
         const articles = data.data?.data?.slice(0, 4).map((item) => ({
            title: item.title,
            link: item.link,
            imageSrc: getImageFromData(item.description.$cdata_section),
         }))
         setNewsArticles(articles)
      }
   }, [data, isSuccess])

   return {
      list: newsArticles,
      isLoading: isLoading || isRefetching,
      isSuccess,
   }
}
