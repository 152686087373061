import React, { createContext, useReducer } from 'react'
import { ContextProviderProps } from './registerContext/types'

export const GlobalStateContext = createContext({})
export const GlobalDispatchContext = createContext({})

const initialState = {
   theme: 'light',
   globalError: {
      message: null,
      stack: null,
   },
   profile: {},
   settings: {},
   columnOrder: [],
   selectedItem: {},
   checkedRows: [],
   selecting: false,
   showModalDetails: false,
   showModalkit: false,
   showModal: false,
   filterStatus: false,
   filtered: {
      searchQuery: null,
      quickFilter: 0,
      ItemTypes: [],
      SelectedConnections: [],
      SelectedCategories: [],
      SelectedManufacturers: [],
      SelectedLocations: [],
   },
}

function reducer(state, action) {
   switch (action.type) {
      case 'SET_GLOBAL_ERROR': {
         return {
            ...state,
            globalError: action.globalError,
         }
      }
      case 'TOGGLE_THEME': {
         return {
            ...state,
            theme: state.theme === 'light' ? 'dark' : 'light',
         }
      }
      case 'UPDATE_THEME': {
         return {
            ...state,
            theme: action.theme,
         }
      }
      case 'SET_PROFILE': {
         return {
            ...state,
            profile: { ...action.profile },
         }
      }
      case 'SET_SETTINGS': {
         return {
            ...state,
            settings: action.settings,
         }
      }
      case 'SET_FILTERS': {
         return {
            ...state,
            filtered: { ...action.filter },
         }
      }
      case 'SET_FILTERS_STATUS': {
         return {
            ...state,
            filterStatus: action.filterStatus,
         }
      }
      case 'RESET_FILTERS': {
         return {
            ...state,
            filtered: initialState.filtered,
         }
      }
      case 'SET_TOOLS': {
         return {
            ...state,
            toolsState: action.toolsData,
         }
      }
      case 'SET_TOOL_COUNT': {
         return {
            ...state,
            toolCount: action.toolCount,
         }
      }
      case 'SET_COLUMN_ORDER': {
         return {
            ...state,
            columnOrder: action.columnOrder,
         }
      }
      case 'SET_SELECTED_ITEM': {
         return {
            ...state,
            selectedItem: action.selectedItem,
         }
      }
      // MODALS
      case 'VIEW_MODAL_DETAILS': {
         return {
            ...state,
            showModalDetails: action.showModalDetails,
         }
      }
      case 'VIEW_MODAL_ADDKIT': {
         return {
            ...state,
            showModalkit: action.showModalkit,
         }
      }
      // END MODALS
      case 'SET_SELECTED': {
         return {
            ...state,
            selecting: action.selecting,
         }
      }
      case 'SET_CHECKED_ROW': {
         return {
            ...state,
            checkedRows: action.checkedRows,
         }
      }
      default:
         throw new Error('Error Action Type')
   }
}

const GlobalContextProvider = ({ children }: ContextProviderProps) => {
   const [state, dispatch] = useReducer(reducer, initialState)
   return (
      <GlobalStateContext.Provider value={state}>
         <GlobalDispatchContext.Provider value={dispatch}>
            {children}
         </GlobalDispatchContext.Provider>
      </GlobalStateContext.Provider>
   )
}

export default GlobalContextProvider
