import React, { memo } from 'react'
import * as noteStyles from './notes.module.scss'
import { Col } from 'react-grid-system'
import { Button, Svg } from '../../common/'
import { truncateMiddle } from '../../../utils/functions'
import { AttachmentBadgeProps } from './types'
import { ToolNotePermission } from '../../../types'

const AttachmentBadge = ({
   attachment,
   handleCheckRemoveAttachment,
   selectedNotePermission,
   selectedNoteHasId,
   isReadonly,
}: AttachmentBadgeProps) => (
   <Col xs={12} md={4} className={noteStyles.attachmentBadge}>
      <Button
         minWidth="100%"
         variant="plainV2"
         isReset
         className={noteStyles.attachmentBadgeButton}
         type={'link'}
         href={attachment?.URL}
         small
         download
      >
         <div>
            <div className={noteStyles.attachmentBtnContent}>
               <Svg
                  id={
                     attachment.Type?.includes('image')
                        ? 'imageIcon'
                        : 'documentIcon'
                  }
               />

               <div className={noteStyles.fileText}>
                  {truncateMiddle(attachment?.FileName)}
               </div>
               <div
                  className={noteStyles.deleteIcon}
                  style={{
                     visibility:
                        (selectedNotePermission >= ToolNotePermission.Delete ||
                           !selectedNoteHasId) &&
                        !isReadonly
                           ? 'visible'
                           : 'hidden',
                  }}
                  onClick={(e) => handleCheckRemoveAttachment(e, attachment)}
               >
                  <Svg id={'delete'} width={1000} height={1200} />
               </div>
            </div>
         </div>
      </Button>
   </Col>
)

export default memo(AttachmentBadge)
