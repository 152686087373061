// extracted by mini-css-extract-plugin
export var available = "tile-new-module--available--0f2a9";
export var borrowed = "tile-new-module--borrowed--1d084";
export var column = "tile-new-module--column--7980a";
export var loaned = "tile-new-module--loaned--4c4b4";
export var pending = "tile-new-module--pending--c871f";
export var tile = "tile-new-module--tile--3ca94";
export var tile_content = "tile-new-module--tile_content--f99e9";
export var tile_dueBack = "tile-new-module--tile_dueBack--fd494";
export var tile_footer = "tile-new-module--tile_footer--a96fa";
export var tile_header = "tile-new-module--tile_header--81dea";
export var tile_icons = "tile-new-module--tile_icons--5dd4d";
export var tile_image = "tile-new-module--tile_image--7b5b6";
export var tile_info = "tile-new-module--tile_info--3156a";
export var tile_keyValue = "tile-new-module--tile_keyValue--82246";
export var tile_location = "tile-new-module--tile_location--efc66";
export var tile_locationDue = "tile-new-module--tile_locationDue--f17d3";
export var tile_locationIcon = "tile-new-module--tile_locationIcon--2a7ac";
export var tile_owner = "tile-new-module--tile_owner--c7fca";
export var tile_status = "tile-new-module--tile_status--3932c";
export var tile_statusLabel = "tile-new-module--tile_statusLabel--059d1";
export var tile_subheader = "tile-new-module--tile_subheader--04194";
export var tile_top = "tile-new-module--tile_top--68066";
export var unavailable = "tile-new-module--unavailable--7fa8a";