// extracted by mini-css-extract-plugin
export var aiIcon = "importModal-module--aiIcon--3cb16";
export var bottomImage = "importModal-module--bottomImage--f33aa";
export var bottomSection = "importModal-module--bottomSection--02581";
export var buttonsWrapper = "importModal-module--buttonsWrapper--d38ce";
export var closeWindowInfo = "importModal-module--closeWindowInfo--a250c";
export var column = "importModal-module--column--b7b63";
export var content = "importModal-module--content--94125";
export var contentWrapper = "importModal-module--contentWrapper--54ec6";
export var description = "importModal-module--description--0a9af";
export var errorContent = "importModal-module--errorContent--bc5fc";
export var greenTriangle = "importModal-module--greenTriangle--34e98";
export var heading = "importModal-module--heading--0fa14";
export var helpLinkWrapper = "importModal-module--helpLinkWrapper--ba086";
export var hideButton = "importModal-module--hideButton--20571";
export var importResults = "importModal-module--importResults--d37b8";
export var leftColumn = "importModal-module--leftColumn--22bd1";
export var middleImage = "importModal-module--middleImage--0ea19";
export var numberedSection = "importModal-module--numberedSection--e0529";
export var progressIndicatorWrapperCenter = "importModal-module--progressIndicatorWrapperCenter--694cd";
export var progressIndicatorWrapperLeft = "importModal-module--progressIndicatorWrapperLeft--00a06";
export var rightColumn = "importModal-module--rightColumn--3e2da";
export var topImage = "importModal-module--topImage--fe1dd";
export var yellowCircle = "importModal-module--yellowCircle--31971";