import { useMemo } from 'react'

import { getPermittedNavItems } from '../../utils/navigation'
import { navList } from '../../constants/navigation'
import { NavItems } from './useNavigation.type'
import { useProfile, useSettings } from '../'

interface UseNavigation {
   loading: boolean
   navItems: NavItems
}

export default function (): UseNavigation {
   const { isUpdating, isLoading, settings } = useSettings()
   const { profile } = useProfile()

   const navItems: NavItems = useMemo(() => {
      return getPermittedNavItems(navList || [], settings, profile)
   }, [settings, profile])

   return {
      loading: isLoading || isUpdating,
      navItems,
   }
}
