import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import { useEffect } from 'react'

export default (): UseConnectionsTotals => {
   const request = useAPI()

   const { data, isLoading, isSuccess, refetch, isRefetching } = useQuery(
      'connection-totals',
      getConnectionTotalsFromAPI
   )

   async function getConnectionTotalsFromAPI(): Promise<
      AxiosResponse<ConnectionTotalsResponse>
   > {
      return await request.get(PATH.CONNECTIONS.TOTALS)
   }

   useEffect(() => {
      refetch()
   }, [])

   return {
      totals: data?.data?.details,
      isLoading: isLoading || isRefetching,
      isSuccess,
   }
}
