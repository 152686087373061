enum UserRoles {
   SUPERUSER = 1,
   COMPANY = 2,
   ADMIN = 3,
   EMPLOYEE = 4,
   VIRTUAL = 6,
}

const isEmployeeRole = (roles: number[]): boolean =>
   roles.includes(UserRoles.EMPLOYEE)

const isAdminRole = (roles: number[]): boolean =>
   roles.includes(UserRoles.ADMIN)

const isCompanyRole = (roles: number[]): boolean =>
   roles.includes(UserRoles.COMPANY)

const isSuperUserRole = (roles: number[]): boolean =>
   roles.includes(UserRoles.SUPERUSER)

const isVirtualUserRole = (roles: number[]): boolean =>
   roles.includes(UserRoles.VIRTUAL)

const getHighestRole = (roles?: number[]): string => {
   if (!roles || roles.length === 0) {
      return ''
   }
   const roleId = roles.reduce(
      (min, current) => (current < min ? current : min),
      roles[0]
   )
   switch (roleId) {
      case UserRoles.SUPERUSER:
         return 'Super User'
      case UserRoles.COMPANY:
         return 'Company'
      case UserRoles.ADMIN:
         return 'Admin'
      case UserRoles.EMPLOYEE:
         return 'Employee'
      case UserRoles.VIRTUAL:
         return 'Vritual'
      default:
         return ''
   }
}

const getRoleIdFromName = (roleName: string) => {
   switch (roleName) {
      case 'admin':
      case 'admins':
         return UserRoles.ADMIN.toString()
      case 'employee':
      case 'employees':
         return UserRoles.EMPLOYEE.toString()
      case 'virtual':
      case 'virtuals':
         return UserRoles.VIRTUAL.toString()
   }
}

export {
   UserRoles,
   isEmployeeRole,
   isAdminRole,
   isCompanyRole,
   isSuperUserRole,
   isVirtualUserRole,
   getHighestRole,
   getRoleIdFromName,
}
