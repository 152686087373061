import React from 'react'
import { CsvAnchorProps } from './csvDownload.types'
import * as styles from './csvDownloadForm.module.scss'
import { saveAs } from 'file-saver'
import { getUser } from '../../../utils/auth'
import { API_KEY, API_URL } from '../../../constants/global'

const CsvAnchor: React.FunctionComponent<CsvAnchorProps> = ({
   fileName,
   path,
   text,
   params,
   setIsDownloading,
   setDownloadPercentage,
}) => {
   const user = getUser()?.access_token
   const req = new XMLHttpRequest()

   const updateProgress = (evt: {
      lengthComputable: any
      loaded: number
      total: number
   }) => {
      if (evt.lengthComputable) {
         const percentComplete = (evt.loaded / evt.total) * 100
         setDownloadPercentage(Math.floor(percentComplete))
      }
   }

   const downloadCsv = (params: {}) => {
      setIsDownloading(true)
      req.onprogress = updateProgress
      req.open('POST', API_URL + path, true)
      req.setRequestHeader('Ocp-Apim-Subscription-Key', API_KEY)
      req.setRequestHeader('Authorization', `Bearer ${user}`)
      req.setRequestHeader('Content-Type', 'application/json')
      req.onreadystatechange = function () {
         if (req.readyState == 4 && req.status == 200) {
            const blob = new Blob([req.response], { type: 'octet/stream' })
            saveAs(blob, fileName)
            setIsDownloading(false)
         }
      }
      req.responseType = 'arraybuffer'
      req.send(JSON.stringify(params))
   }

   return (
      <a onClick={() => downloadCsv(params)} className={styles.buttonAnchor}>
         {text}
      </a>
   )
}

export default CsvAnchor
