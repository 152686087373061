import React, { memo } from 'react'

import * as styles from './keyValue.module.scss'
import Svg from '../svg/svg'
import { KeyValueProps } from './types'

const KeyValue = ({ className, label, value, svgId }: KeyValueProps) => {
   const displayValue = value == null ? 'n/a' : !value ? '-' : value
   return (
      <div className={className}>
         <div className={styles.label}>
            {svgId && <Svg id={svgId} width={22} height={17} />}
            {label}
         </div>
         <div className={styles.value}>{displayValue}</div>
      </div>
   )
}

export default memo(KeyValue)
