import { useMutation, useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { isLoggedIn } from '../../utils/auth'
import { useAPI } from '../'
import { PATH } from '../../constants/global'

import { UseProfileData } from './useProfile.type'

const cacheIdentifier = 'profile'

export default function (): UseProfileData {
   const request = useAPI()

   const { data, isLoading, isFetching, refetch } = useQuery(
      [cacheIdentifier, isLoggedIn],
      getProfileFromAPI,
      {
         enabled: isLoggedIn(),
      }
   )

   const {
      isLoading: isSaving,
      isSuccess,
      mutate: mutateSettings,
   } = useMutation(updateProfileSettingsInAPI)

   const {
      isLoading: isSavingDetails,
      isSuccess: isDetailsUpdatedSuccess,
      mutate: mutateDetails,
   } = useMutation(updateProfileDetailsInAPI)

   async function getProfileFromAPI(): Promise<AxiosResponse> {
      try {
         return await request.get(PATH.PROFILE.GET)
      } catch (error) {
         // TODO: handle errors better
         console.log(error)
      }
   }

   async function updateProfileSettingsInAPI(profile: {}): Promise<AxiosResponse> {
      try {
         return await request.post(PATH.PROFILE.UPDATE_SETTINGS, { ...profile })
      } catch (error) {
         // TODO: handle errors better
         console.log(error)
      }
   }

   async function updateProfileDetailsInAPI(profile: {}): Promise<AxiosResponse> {
      try {
         return await request.post(PATH.PROFILE.UPDATE_DETAILS, { ...profile })
      } catch (error) {
         // TODO: handle errors better
         console.log(error)
      }
   }

   const updateProfileSettings = (profile: {}) => {
      mutateSettings(profile)
   }

   const updateProfileDetails = (profile: {}) => {
      mutateDetails(profile)
   }

   return {
      isLoading,
      isFetching,
      isSaving,
      isSavingDetails,
      isDetailsUpdatedSuccess,
      isSuccess,
      profile: data?.data || {},
      refresh: refetch,
      updateProfileSettings,
      updateProfileDetails,
   }
}
