import React, { useMemo } from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

import * as styles from './navItem.module.scss'
import { useNotificationTotals } from '../../../../hooks'
import { TasksNavItemProps } from './types'

const TasksNavItem = ({ path, label, icon }: TasksNavItemProps) => {
   const { isLoading: isTaskTotalsLoading, totalNotifications: totalTasks } =
      useNotificationTotals()

   const badge = useMemo(
      () => (
         <div
            className={classNames(styles.navItemCircle, {
               [styles.hidden]: isTaskTotalsLoading,
            })}
         >
            {totalTasks}
         </div>
      ),
      [totalTasks, isTaskTotalsLoading]
   )

   return (
      <Link className={styles.navItemLink} to={path}>
         {icon}
         {label}
         {badge}
      </Link>
   )
}

export default TasksNavItem
