import { useEffect, useState } from 'react'

import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { UseTotalCount, TotalCountData, GetItemsRequestOptions } from '../../types'
import { useAPI } from '../'

export default function (): UseTotalCount {
   const request = useAPI()
   const [pageOptions, setPageOptions] = useState<GetItemsRequestOptions>({})

   const [config, setConfig] = useState({
      COUNT: '',
      cacheIdentifier: '',
      key: '',
   })

   const cacheIdentifier = config.cacheIdentifier

   async function getItemsFromAPI(options: {}): Promise<
      AxiosResponse<TotalCountData[]>
   > {
      if (config.cacheIdentifier) {
         return request.post(config.COUNT, {
            ...options,
         })
      }
   }

   const setCountPath = (path, cacheIdentifier) => {
      {
         path &&
            setConfig({
               COUNT: path.COUNT,
               cacheIdentifier,
               key: cacheIdentifier[0].toUpperCase() + cacheIdentifier.slice(1),
            })
      }
   }

   const { data, refetch, isLoading, isSuccess } = useQuery<
      Promise<AxiosResponse>
   >([cacheIdentifier], () =>
      getItemsFromAPI({ ...pageOptions, isForMultiselectFilter: true })
   )

   const getCount = (obj: GetItemsRequestOptions) => setPageOptions(obj)

   useEffect(() => {
      refetch()
   }, [pageOptions])

   return {
      data: data?.data?.Count,
      getCount,
      isLoading,
      isSuccess,
      setCountPath,
   }
}
