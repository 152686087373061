import React, { memo, ReactNode } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

type SeoProps = {
   title: string
   description?: string
   footnote?: string
   lang?: string
   children?: ReactNode
}

const Seo = ({
   title,
   description = '',
   footnote = '',
   lang = 'en',
   children,
}: SeoProps) => {
   const { site } = useStaticQuery(
      graphql`
         query {
            site {
               siteMetadata {
                  title
                  description
                  footnote
                  author
               }
            }
         }
      `
   )

   const metaDescription = description || site.siteMetadata.description
   const defaultTitle = site.siteMetadata?.title
   const footnoteMeta = footnote || site.siteMetadata.footnote
   const siteAuthor = site.siteMetadata?.author || ''
   const computedTitle = defaultTitle ? `${title} - ${defaultTitle}` : title

   return (
      <>
         <html lang={lang} />
         <title>{computedTitle}</title>
         <meta name="description" content={metaDescription} />
         <meta name="footnote" content={footnoteMeta} />
         <meta property="og:title" content={title} />
         <meta property="og:description" content={metaDescription} />
         <meta property="og:type" content="website" />
         <meta name="twitter:card" content="summary" />
         <meta name="twitter:creator" content={siteAuthor} />
         <meta name="twitter:title" content={title} />
         <meta name="twitter:description" content={metaDescription} />
         {children}
      </>
   )
}

export default memo(Seo)
