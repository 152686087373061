import React, { memo } from 'react'

import * as styles from './noResults.module.scss'
import { NoResultsProps } from './types'

const NoResults = ({ children, fullWidth }: NoResultsProps) => (
   <div className={`${styles.wrapper} ${fullWidth ? styles.fullWidth : ''}`}>
      {children}
   </div>
)

export default memo(NoResults)
