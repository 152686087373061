const crudItemTabs = [
   { label: 'Details', status: 0 },
   { label: 'Images', status: 1 },
   { label: 'Other', status: 2 },
   { label: 'Rental', status: 3 },
   { label: 'Notes', status: 4 },
   { label: 'History', status: 5 },
   { label: 'Map', status: 6 },
]

export { crudItemTabs }
