import React, { memo, useMemo } from 'react'
import classNames from 'classnames'

import * as styles from './image.module.scss'
import { fallbackImage } from './constants'

const Image = ({
   alt,
   className,
   defaultImagePadding,
   height,
   padding = 0,
   url,
   width,
   lazyLoading,
}: ImageProps) => {
   const imageHeight = useMemo(
      () => (!!height ? height : width),
      [height, width]
   )

   const imagePadding = useMemo(
      () => (!url ? defaultImagePadding || padding : padding),
      [padding, defaultImagePadding, url]
   )

   const imageUrl = useMemo(() => (!url ? fallbackImage : url), [url])

   return (
      <span
         className={classNames(styles.wrapper, className)}
         style={{
            height: imageHeight,
            padding: imagePadding,
            width,
         }}
      >
         <img
            className={styles.image}
            src={imageUrl}
            alt={alt}
            loading={lazyLoading ? 'lazy' : 'eager'}
         />
      </span>
   )
}

export default memo(Image)
