import React, { forwardRef, LegacyRef, memo, useCallback } from 'react'
import Tippy from '@tippyjs/react/headless' // different import path!

import * as styles from './tooltip.module.scss'
import { TooltipProps } from './types'

const Tooltip = ({
   isVisible,
   setIsVisible,
   children,
   content,
   placement = 'auto',
   padding,
}: TooltipProps) => {
   const onClick = useCallback(
      () => (setIsVisible ? setIsVisible(!isVisible) : null),
      [setIsVisible]
   )

   const ChildrenWrapper = forwardRef(
      (_props, ref: LegacyRef<HTMLDivElement>) => {
         return (
            <div ref={ref} className={styles.childWrapper} onClick={onClick}>
               {children ?? <></>}
            </div>
         )
      }
   )

   const renderContent = useCallback(
      (attrs) => (
         <div
            className={styles.content}
            style={{ padding: padding }}
            tabIndex={-1}
            {...attrs}
         >
            {content}
         </div>
      ),
      [content, padding]
   )

   const onClickOutside = useCallback(
      () => (setIsVisible ? setIsVisible(false) : null),
      [setIsVisible]
   )

   return (
      <Tippy
         render={renderContent}
         placement={placement}
         visible={isVisible}
         onClickOutside={onClickOutside}
      >
         <ChildrenWrapper />
      </Tippy>
   )
}

export default memo(Tooltip)
