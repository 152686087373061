import React, { memo } from 'react'

import * as styles from './widgetList.module.scss'
import WidgetListItem from './widgetListItem/widgetListItem'

const WidgetList = ({ items, onItemClick }: WidgetListProps) => (
   <ul className={styles.list}>
      {items.map((item) => (
         <WidgetListItem
            key={item.label}
            label={item.label}
            amount={item.amount}
            image={item.image}
            onClick={onItemClick}
         />
      ))}
   </ul>
)

export default memo(WidgetList)
