import React, { memo, useCallback } from 'react'
import * as styles from './mapLegend.module.scss'
import Svg from '../../../svg/svg'

const MapLegend = ({
   handleCheckBoxToggle,
   isGpsChecked,
   isBluetoothChecked,
   showChecks,
}: MapLegendProps) => {
   const handleGPSToggle = useCallback(
      () => handleCheckBoxToggle('GPS'),
      [handleCheckBoxToggle]
   )

   const handleBluetoothToggle = useCallback(
      () => handleCheckBoxToggle('BT'),
      [handleCheckBoxToggle]
   )

   return (
      <div className={styles.subheader}>
         <span className={styles.mapKey}>
            <div className={styles.mapKeySvg}>
               <Svg id="pinNoShadow" fill={'#FFCA05'} width={15} />
            </div>

            <span className={styles.mapKeyValue}>Assigned Location</span>
         </span>
         <span className={styles.mapKey}>
            <div className={styles.mapKeySvg}>
               <Svg id="lastSeenArea" width={15} />
            </div>
            <span className={styles.mapKeyValue}>Area Last Seen</span>
         </span>
         {showChecks && (
            <div className={styles.checkboxWrapper}>
               <div className={styles.checkContainer} onClick={handleGPSToggle}>
                  <input
                     className={styles.nativeCheckbox}
                     type="checkbox"
                     checked={isGpsChecked}
                  />
                  <span className={styles.checkmark}></span> GPS Scans
               </div>

               <div
                  className={styles.checkContainer}
                  onClick={handleBluetoothToggle}
               >
                  <input
                     className={styles.nativeCheckbox}
                     type="checkbox"
                     checked={isBluetoothChecked}
                  />
                  <span className={styles.checkmark}></span> Bluetooth Pings
               </div>
            </div>
         )}
      </div>
   )
}

export default memo(MapLegend)
