import React, { memo, useMemo } from 'react'

import { Button, LoadingOverlay } from '..'

import * as styles from './widgetBox.module.scss'
import { WidgetBoxProps } from './types'
import CountUp from 'react-countup'
import classNames from 'classnames'

const WidgetBox = ({
   children,
   heading,
   id,
   total,
   showSpinner = false,
   onButtonClick,
   transparentOverlay,
   leftAlignHeading,
   disableCountUp,
}: WidgetBoxProps) => {
   const totalToDisplay = useMemo(
      () => (total && typeof total === 'number' && !isNaN(total) ? total : 0),
      [total]
   )

   const headerClassName = useMemo(
      () => (total === undefined && !leftAlignHeading ? styles.centered : ''),
      [total, leftAlignHeading]
   )

   const buttonLabel = useMemo(() => {
      switch (id) {
         case 'Items':
            return 'Add Items'
         case 'Open':
            return 'View Audits'
         case 'Locations':
            return 'Manage Locations'
         case 'News':
            return 'View More'
         default:
            return `View ${id}`
      }
   }, [id])

   const widgetClassName = useMemo(
      () =>
         classNames(styles.widget, {
            [styles.transparentOverlay]: transparentOverlay,
         }),
      [transparentOverlay]
   )

   return (
      <article className={widgetClassName}>
         <header className={headerClassName}>
            <h2>{heading}</h2>
            {id && total !== undefined && (
               <div>
                  <p className={styles.totalsText}>Total {id}</p>
                  <p className={styles.totalsAmount}>
                     {disableCountUp ? (
                        totalToDisplay
                     ) : (
                        <CountUp
                           start={0}
                           end={totalToDisplay}
                           duration={3}
                           useEasing
                        />
                     )}
                  </p>
               </div>
            )}
         </header>
         <section>
            {showSpinner ? (
               <LoadingOverlay positionAbsolute small noBackground />
            ) : (
               children
            )}
         </section>
         {onButtonClick && (
            <footer>
               <Button
                  type="button"
                  variant="text"
                  onClick={onButtonClick}
                  minWidth="max-content"
               >
                  {buttonLabel}
               </Button>
            </footer>
         )}
      </article>
   )
}

export default memo(WidgetBox)
