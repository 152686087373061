import { useMutation } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import { UseLocations } from './useLocations.type'
import { useState } from 'react'

export default function (): UseLocations {
   const request = useAPI()
   const [isImportSuccess, setIsImportSuccess] = useState<boolean>(false)
   const [importError, setImportError] = useState<string | null>(null)
   const [importFileErrors, setImportFileErrors] = useState<string[]>([])
   const [totalImported, setTotalImported] = useState<number>(0)

   const { isLoading: isImporting, mutate } = useMutation(importLocationsFile, {
      onSuccess: (data) => {
         const { IsValid, Result, ErrorMessage } = data?.data
         if (IsValid) {
            setIsImportSuccess(true)
            setTotalImported(Result[0])
         } else {
            setIsImportSuccess(false)
            const msg =
               ErrorMessage ?? 'Unknown error with the uploaded locations file.'
            setImportError(msg)
         }
      },
      onError: (error: any) => {
         setIsImportSuccess(false)
         const msg =
            error?.response?.data?.Message ??
            'Unknown error importing locations.'
         setImportError(msg)
         const msgs = error?.response?.data?.ModelState?.file ?? [
            'Unknown error with the uploaded locations file.',
         ]
         setImportFileErrors(msgs)
      },
   })

   async function importLocationsFile(file: File): Promise<AxiosResponse<any>> {
      const formData = new FormData()
      formData.append('file', file)

      return await request.post(PATH.LOCATIONS.IMPORT, formData, {
         headers: {
            'Content-Type': 'multipart/form-data',
         },
      })
   }

   const resetImportState = () => {
      setIsImportSuccess(false)
      setImportError(null)
      setImportFileErrors([])
   }

   const importLocations = async (file: File) => {
      resetImportState()
      await mutate(file)
   }

   return {
      importLocations,
      isImporting,
      isImportSuccess,
      isImportError: importError !== null || importFileErrors?.length > 0,
      importError,
      importFileErrors,
      totalImported,
   }
}
