import React, { memo, useMemo } from 'react'
import * as styles from './mapInfoCardModal.module.scss'
import { formatLocaleDate } from '../../../../utils/functions'
import { GPSScanEvents } from '../../../../enums/GPS_Bluetooth'
import { Modal } from '../../modal'
import Svg from '../../svg/svg'
import { MapInfoCardModalProps } from './types'

const MapInfoCardModal = ({
   isInfoCardOpen,
   handleCloseInfoCard,
   selectedLocation,
   reverseGeoLookUpResults,
}: MapInfoCardModalProps) => {
   const isCurrentLocation = useMemo(
      () => !!selectedLocation.CurrentLocation,
      [selectedLocation]
   )

   const infoSvgId = useMemo(() => {
      switch (selectedLocation?.Event) {
         case GPSScanEvents.AuditScan: //
            return 'audit' //
         case GPSScanEvents.BorrowToolRequest: //
            return 'actionReturn' //
         case GPSScanEvents.AcceptedBorrowToolRequest: //
            return 'actionReturn' //
         case GPSScanEvents.BarcodeScan: //
            return 'cardViewBarcode' //
         case GPSScanEvents.LoanToolRequest: //
            return 'actionLoan' //
         case GPSScanEvents.AcceptedLoanToolRequest: //
            return 'actionLoan' //
         case GPSScanEvents.ReturnRequest: //
            return 'actionReturn' //
         case GPSScanEvents.AcceptedReturnRequest: //
            return 'actionReturn' //
         case GPSScanEvents.TransferToolFromRequest: //
            return 'actionTransfer' //
         case GPSScanEvents.TransferToolToRequest:
            return 'actionTransfer'
         case GPSScanEvents.ToolNoteCreated: //
            return 'notes' //
         case GPSScanEvents.UpdateItemUnavailable: //
            return 'make-unavailable' //
         case GPSScanEvents.UpdateItemAvailable: //
            return 'make-available' //
         case GPSScanEvents.LocationTransferred: //
            return 'actionTransfer' //
         case GPSScanEvents.AddedTokit: //
            return 'add-to-kit' //
         case GPSScanEvents.RemovedFromKit: //
            return 'remove-from-kit' //
         case GPSScanEvents.WarehouseTransfer: //
            return 'actionTransfer' //
         case GPSScanEvents.AcceptedTransferToolFromRequest: //
            return 'actionTransfer' //
         case GPSScanEvents.AcceptedTransferToolToRequest: //
            return 'actionTransfer' //
         default:
            return ''
      }
   }, [selectedLocation])

   const modalTitle = useMemo(
      () => (isCurrentLocation ? 'Current Location' : 'GPS Scan'),
      [isCurrentLocation]
   )

   const modalInnerStyles = {
      top: '0',
      left: '0',
      marginRight: '2vw',
      position: 'absolute',
      opacity: 1,
      background: 'white',
      boxShadow: '0px 0px 10px 0px #00000040',
      border: '1px solid #CFD4DA',
      borderRadius: '5px',
   }

   return (
      <Modal
         isModalVisible={isInfoCardOpen}
         closeModal={handleCloseInfoCard}
         title={modalTitle}
         displayOverlay={false}
         zIndex="999"
         modalInnerStyles={modalInnerStyles}
      >
         {isCurrentLocation ? (
            <div className={styles.infoContent}>
               <div className={styles.scannedDetails}>
                  <span>
                     <span className={styles.svgWrapper}>
                        <Svg
                           id={'pinNoShadow'}
                           width={12}
                           height={16}
                           fill={'#FFCA05'}
                        />
                     </span>{' '}
                     <span className={styles.label}>
                        {selectedLocation.CurrentLocation}
                     </span>
                  </span>
               </div>
               {reverseGeoLookUpResults && (
                  <div className={styles.geoLocation}>
                     Near {reverseGeoLookUpResults}
                  </div>
               )}
               <div className={styles.geoLocation}>
                  {selectedLocation.Latitude} {selectedLocation.Longitude}
               </div>
            </div>
         ) : (
            <div className={styles.infoContent}>
               <div className={styles.scannedDetails}>
                  <span className={styles.scannedInner}>
                     <div className={styles.svgWrapper}>
                        <Svg id={'gps'} width={22} />
                     </div>
                     <div className={styles.label}>
                        {selectedLocation?.CardHeading}
                     </div>
                  </span>
                  <span className={styles.label}>
                     {formatLocaleDate(selectedLocation.CreatedOn)}
                  </span>
               </div>

               <div className={styles.scannedDetails}>
                  <span className={styles.scannedInner}>
                     <div className={styles.svgWrapper}>
                        <Svg id={infoSvgId} width={18} height={16} />
                     </div>
                     <div className={styles.label}>
                        {selectedLocation?.CardDescription ?? 'N/A'}
                     </div>
                  </span>
               </div>
               <div className={styles.geoLocation}>
                  Near {reverseGeoLookUpResults ?? 'N/A'}
               </div>
               <div className={styles.geoLocation}>
                  {selectedLocation.Latitude} {selectedLocation.Longitude}
               </div>
            </div>
         )}
      </Modal>
   )
}

export default memo(MapInfoCardModal)
