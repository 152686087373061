import React, { useEffect, useState } from 'react'
import useBreakpoint from 'use-breakpoint'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'

import { BREAKPOINTS } from '../../../constants/global'
import { NavItem } from './navItem/navItem'
import * as styles from './nav.module.scss'
import { isChildOfItem } from '../../../utils/navigation'
import { useNavigation } from '../../../hooks'
import { LoadingOverlay } from '../'

import { Paths } from './../../../constants/structure'
import { FailureLogout } from './navItem/FailureLogout'

export const Nav = () => {
   const { pathname } = useLocation()
   const { loading, navItems } = useNavigation()
   const { breakpoint } = useBreakpoint(BREAKPOINTS)
   const [currentNavItemOpen, setCurrentNavItemOpen] = useState<string | null>(
      null
   )
   const [navHasError, setNavHasError] = useState<boolean>(false)

   const handleMenuItemToggle = (href: string) => {
      // help does not need it's own page so if href is /help/ stay on current page just expand main navItem
      if (href === Paths.HELP) {
         // navigate(pathname);
         if (currentNavItemOpen === href) {
            setCurrentNavItemOpen(null)
         } else {
            setCurrentNavItemOpen(href)
         }
         return
      }

      // if we're on desktop we want to navigate when we click on a parent page
      // as well as opening the subnav
      if (breakpoint !== 'mobile') {
         navigate(href)
      } else {
         setCurrentNavItemOpen(href)
      }
   }

   useEffect(() => {
      if (!navItems.length) {
         setNavHasError(true)
      } else {
         setNavHasError(false)
      }
   }, [navItems])

   useEffect(() => {
      setCurrentNavItemOpen(location.pathname)
   }, [pathname])

   if (loading) {
      return <LoadingOverlay />
   }

   return (
      <nav>
         <ul className={styles.nav}>
            {!navHasError &&
               navItems.map((item) => (
                  <NavItem
                     key={`${item.route.path}-${item.label}`}
                     {...item}
                     route={item.route}
                     isOpen={
                        item.route.path === currentNavItemOpen ||
                        isChildOfItem(item?.children, pathname)
                     }
                     isPrimary
                     onToggle={() => handleMenuItemToggle(item.route.path)}
                     currentNavItem={null}
                  />
               ))}
            {navHasError && <FailureLogout />}
         </ul>
      </nav>
   )
}
