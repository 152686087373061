import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import { LocationsResponse, UseDashboardLocations } from './types'
import { useEffect } from 'react'

export default function (): UseDashboardLocations {
   const request = useAPI()

   const { data, isLoading, isRefetching, refetch } = useQuery(
      'active-locations-count',
      getActiveLocationsFromAPI
   )

   async function getActiveLocationsFromAPI(): Promise<
      AxiosResponse<LocationsResponse>
   > {
      return await request.get(PATH.LOCATIONS.LIST_WITH_ITEM_COUNT)
   }

   useEffect(() => {
      refetch()
   }, [])

   return {
      list: data?.data,
      isLoading: isLoading || isRefetching,
   }
}
