import React, { memo, useMemo } from 'react'
import { ImSpinner4 as Spinner } from 'react-icons/im'

import * as styles from './loadingOverlay.module.scss'
import classNames from 'classnames'

const LoadingOverlay = ({
   positionAbsolute,
   small = false,
   xs = false,
   xxs = false,
   noBackground,
}: LoadingOverlayProps) => {
   const className = classNames(styles.wrapper, {
      [styles.absolute]: positionAbsolute,
      [styles.noBackground]: noBackground,
   })

   const size = useMemo(
      () => (small ? 28 : xs ? 16 : xxs ? 10 : 40),
      [small, xs, xxs]
   )

   return (
      <div className={className}>
         <Spinner className={styles.icon} size={size} />
      </div>
   )
}

export default memo(LoadingOverlay)
