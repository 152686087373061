import { useContext, useEffect } from 'react'
import axios from 'axios'
import { getUser } from '../../utils/auth'
import { API_KEY, API_URL } from '../../constants/global'
import { UseAPI } from './useAPI.type'
import { GlobalDispatchContext } from '../../context/globalContextProvider'

export default function (): UseAPI {
   const dispatch = useContext(GlobalDispatchContext)

   const headers = {
      Authorization: `Bearer ${getUser()?.access_token}`,
      'Ocp-Apim-Subscription-Key': API_KEY,
   }

   const instance = axios.create({
      baseURL: API_URL,
      headers: {
         common: headers,
      },
   })

   const setSystemError = (type: string, message: string, stack: any) => {
      dispatch({ type: type, globalError: { message, stack } })
   }

   const resetSystemError = () => {
      dispatch({
         type: 'SET_GLOBAL_ERROR',
         globalError: { message: null, stack: null },
      })
   }

   instance.interceptors.response.use(
      (response) => {
         // successful response, just pass it on
         return response
      },
      (error) => {
         if (error.response.status === 503 || error.response.status === 401) {
            setSystemError('SET_GLOBAL_ERROR', error.message, error.stack)
         }

         // if it's not a catastrophic failure, just pass it on and let the
         // originating code deal with it
         return Promise.reject(error)
      }
   )

   useEffect(() => {
      resetSystemError()
   }, [])

   return instance
}
