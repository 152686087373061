import { FLOW_TYPES } from '../../context/registerContext/types'

export function getFlowType(
   isEmailRegistered: boolean,
   isFreeUser: boolean
): FLOW_TYPES {
   if (!isEmailRegistered) {
      return FLOW_TYPES.TRIAL
   }

   if (isFreeUser) {
      return FLOW_TYPES.UPGRADE
   }

   return FLOW_TYPES.EXISTING
}
