import { TooltipPlacement } from './types'

const getRandomTooltipPlacement = (
   placements: TooltipPlacement[]
): TooltipPlacement => {
   const randomIndex = Math.floor(Math.random() * placements.length)
   return placements[randomIndex]
}

export { getRandomTooltipPlacement }
