// extracted by mini-css-extract-plugin
export var column = "crudItems-module--column--9077d";
export var columnImageActionButtons = "crudItems-module--column-image-action-buttons--f8871";
export var columnImages = "crudItems-module--column-images--a2671";
export var columnOne = "crudItems-module--column-one--88682";
export var columnOneThree = "crudItems-module--column-one-three--34662";
export var columnThreeThree = "crudItems-module--column-three-three--818b5";
export var columnTwo = "crudItems-module--column-two--41239";
export var columnTwoThree = "crudItems-module--column-two-three--cde9a";
export var combinedElement = "crudItems-module--combined-element--e613e";
export var copyPrompt = "crudItems-module--copy-prompt--81174";
export var crudItems = "crudItems-module--crud-items--b7286";
export var currency = "crudItems-module--currency--081e4";
export var detailsPanel = "crudItems-module--details-panel--337d1";
export var detailsPanelTrackingInfo = "crudItems-module--details-panel-tracking-info--42bc8";
export var dropdown = "crudItems-module--dropdown--646f7";
export var dropdownClear = "crudItems-module--dropdown-clear--5dfc8";
export var dropdownWrapper = "crudItems-module--dropdown-wrapper--fdd91";
export var inputElement = "crudItems-module--input-element--cce3b";
export var inputLabel = "crudItems-module--input-label--02de5";
export var itemColumns = "crudItems-module--item-columns--9c5ac";
export var itemDetail = "crudItems-module--itemDetail--addbd";
export var itemDetailLabel = "crudItems-module--itemDetailLabel--da4af";
export var itemDetailValue = "crudItems-module--itemDetailValue--5b5ce";
export var itemDetailValue_lat_lng = "crudItems-module--itemDetailValue_lat_lng--db3a0";
export var itemDetailsBlock = "crudItems-module--item-details-block--4ffa4";
export var itemHistoryContainer = "crudItems-module--item-history-container--a9263";
export var itemMainImage = "crudItems-module--itemMainImage--54c67";
export var itemNotesContainer = "crudItems-module--item-notes-container--5ba7a";
export var mask = "crudItems-module--mask--85e51";
export var notCompleted = "crudItems-module--not-completed--8ba44";
export var note = "crudItems-module--note--b437f";
export var placeholder = "crudItems-module--placeholder--9f77d";
export var row = "crudItems-module--row--1e66f";
export var status = "crudItems-module--status--ca609";
export var statusAvailable = "crudItems-module--status-available--d8915";
export var statusBorrowed = "crudItems-module--status-borrowed--bd58e";
export var statusLoaned = "crudItems-module--status-loaned--63f21";
export var statusPending = "crudItems-module--status-pending--16137";
export var statusUnavailable = "crudItems-module--status-unavailable--ec1d1";
export var timeline = "crudItems-module--timeline--233d0";
export var toggle = "crudItems-module--toggle--57871";
export var toggleInfo = "crudItems-module--toggleInfo--d654d";
export var toggleWrapper = "crudItems-module--toggleWrapper--7e834";
export var totalValue = "crudItems-module--total-value--004ea";
export var totalValueKey = "crudItems-module--total-value-key--560cc";
export var zoomInIcon = "crudItems-module--zoomInIcon--42399";