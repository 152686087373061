import { useMutation } from 'react-query'
import { AxiosResponse } from 'axios'
import { useAPI } from '..'
import { delayedReset } from '../../utils/functions'
import { PATH } from '../../constants/global'

interface UseUpdateNoteData {
   update?: (obj: any) => void
   add?: (obj: any) => void
   remove?: (obj: any) => void
   isSuccessUpdate: boolean
   isErrorUpdate: boolean
   isSuccessCreate: boolean
   isSuccessRemove: boolean
   isCreating: boolean
   isUpdating: boolean
   isRemoving: boolean
   isErrorCreate: boolean
   isErrorRemove: boolean
}

export default function (): UseUpdateNoteData {
   const request = useAPI()

   // CUSTOM ACTIONS FOR TABLE - create,edit,delete
   const {
      error: createError,
      isError: isErrorCreate,
      isLoading: isCreating,
      isSuccess: isSuccessCreate,
      mutate: mutateCreate,
      reset: resetCreate,
   } = useMutation(addItemsAPI, {
      onSettled: async () => {
         delayedReset(resetCreate)
      },
   })
   const {
      error: updateError,
      isError: isErrorUpdate,
      isLoading: isUpdating,
      isSuccess: isSuccessUpdate,
      mutate: mutateUpdate,
      reset: resetUpdate,
   } = useMutation(updateItemsAPI, {
      onSettled: async () => {
         delayedReset(resetUpdate)
      },
   })
   const {
      error: deleteError,
      isError: isErrorRemove,
      isLoading: isRemoving,
      isSuccess: isSuccessRemove,
      mutate: mutateRemove,
      reset: resetRemove,
   } = useMutation(removeItemsAPI, {
      onSettled: async () => {
         delayedReset(resetRemove)
      },
   })

   async function updateItemsAPI(obj: {}): Promise<AxiosResponse> {
      return request.post(PATH.NOTES.UPDATE, obj)
   }

   async function addItemsAPI(obj: any): Promise<AxiosResponse> {
      return request.post(PATH.NOTES.ADD, obj)
   }

   async function removeItemsAPI(value: any): Promise<AxiosResponse> {
      if (Number(value)) {
         return request.post(PATH.NOTES.DELETE + value)
      }
      return request.post(PATH.NOTES.DELETE, value)
   }

   const update = async (obj: any) => {
      mutateUpdate(obj)
   }

   const add = async (obj: any) => {
      mutateCreate(obj)
   }

   const remove = async (obj: any) => {
      mutateRemove(obj)
   }

   return {
      update,
      add,
      remove,
      isSuccessUpdate,
      isErrorUpdate,
      isSuccessCreate,
      isSuccessRemove,
      isCreating,
      isUpdating,
      isRemoving,
      isErrorCreate,
      isErrorRemove,
   }
}
