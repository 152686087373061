import React, { memo } from 'react'
import * as noteStyles from './notes.module.scss'
import { Col, Row } from 'react-grid-system'
import { Button, LoadingOverlay, Svg } from '../../common/'

import AttachmentBadge from './attachmentBadge'
import { ToolNotePermission } from '../../../types'
import { NoteAttachmentPickerProps } from './types'

const NoteAttachmentPicker = ({
   attachments,
   handleAddAttachment,
   handleCheckRemoveAttachment,
   isUploading,
   isDeleteAttachmentPending,
   selectedNotePermission,
   selectedNoteHasId,
   isReadonly,
}: NoteAttachmentPickerProps) => (
   <section className={noteStyles.attachmentWrapper}>
      <header className={noteStyles.attachmentHeader}>
         <Svg id="attachmentPaperclip" width={23} />
         Attachments
         {(isUploading || isDeleteAttachmentPending) && (
            <>
               <div className={noteStyles.attachmentLoadingSpinner}>
                  <div>
                     <LoadingOverlay positionAbsolute xs />
                  </div>

                  <small className={noteStyles.attachmentLoadingText}>
                     {isUploading
                        ? '...uploading'
                        : isDeleteAttachmentPending
                        ? '...removing'
                        : ''}
                  </small>
               </div>
            </>
         )}
      </header>

      <div className={noteStyles.attachmentInner}>
         <Row gutterWidth={20}>
            {attachments.map((attachment, i) => (
               <AttachmentBadge
                  key={`${attachment.FileName}_${i}`}
                  attachment={attachment}
                  handleCheckRemoveAttachment={handleCheckRemoveAttachment}
                  selectedNotePermission={selectedNotePermission}
                  selectedNoteHasId={selectedNoteHasId}
                  isReadonly={isReadonly}
               />
            ))}
            <Col xs={12} md={4}>
               {(selectedNotePermission > ToolNotePermission.View ||
                  !selectedNoteHasId) &&
                  !isReadonly && (
                     <Button
                        minWidth="100%"
                        variant="plainV2"
                        isReset
                        onClick={handleAddAttachment}
                        small={true}
                     >
                        <Svg id="plus" />
                     </Button>
                  )}
            </Col>
         </Row>
      </div>
   </section>
)

export default memo(NoteAttachmentPicker)
