import { MapOptions } from 'google-map-react'
import { MapCoordinates } from './types'

const minimumMapZoom = 6

const maximumMapZoom = 15

const defaultMapOptions: MapOptions = {
   controlSize: 28,
   scrollwheel: false,
   fullscreenControl: false,
}

const executeReverseGeocodeLookup = (
   latlngObject: MapCoordinates,
   retrieveResultsFunction
) => {
   const geocoder = new google.maps.Geocoder()
   geocoder
      //  @ts-ignore
      .geocode({ location: latlngObject })
      //  @ts-ignore
      .then((response) => {
         if (response.results[0]) {
            retrieveResultsFunction(response.results[0].formatted_address)
         } else {
            retrieveResultsFunction('No Lookup details found')
         }
      })
      .catch((e) => console.log('Geocoder failed due to: ' + e))
}

export {
   minimumMapZoom,
   maximumMapZoom,
   defaultMapOptions,
   executeReverseGeocodeLookup,
}
