import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { PATH } from '../../constants/global'

import { UseTableData } from '../../types'
import { useAPI } from '../'

export default function (): UseTableData {
   const request = useAPI()
   const [pageOptions, setPageOptions] = useState('')
   const cacheIdentifier = 'item-notes'

   async function getItemsFromAPI(options: string): Promise<AxiosResponse> {
      if (options) {
         return request.get(PATH.ITEMS_NOTE.LIST + '/' + options)
      }
   }

   const { data, refetch, isLoading, isRefetching, isSuccess } = useQuery<
      Promise<AxiosResponse>
   >([cacheIdentifier, pageOptions.toString()], () =>
      getItemsFromAPI(pageOptions)
   )

   const itemNotesList = (str?: string | number) => {
      setPageOptions(str.toString())
   }

   useEffect(() => {
      refetch()
   }, [pageOptions])

   return {
      data: data?.data,
      itemNotesList,
      isLoading,
      isRefetching,
      refetch,
      isSuccess,
   }
}
