import React, { memo } from 'react'
import CountUp from 'react-countup'
import classNames from 'classnames'

import { Svg } from '../../common'

import * as styles from './statTile.module.scss'

const StatTile = ({
   color,
   icon,
   isDisabled = false,
   label,
   num,
   onClick,
}: StatTileProps) => (
   <button
      type="button"
      className={classNames(styles.tile, {
         [styles.tileDisabled]: isDisabled,
      })}
      onClick={onClick}
      style={{ color, fill: color }}
      disabled={isDisabled}
   >
      <div>
         <Svg id={icon} />
      </div>
      <div className={styles.number}>
         <CountUp start={0} end={num} duration={3} useEasing />
      </div>
      <div className={styles.label} style={{ color }}>
         {label}
      </div>
   </button>
)

export default memo(StatTile)
