import React, { memo, useMemo } from 'react'
import * as styles from './matchedImage.module.scss'
import { MatchedImageProps } from './types'
import Svg from '../svg/svg'

const MatchedImage = ({ image, onClick, disabled }: MatchedImageProps) => {
   const { PictureFullURL: src, selected, Position } = image

   const stateClass = useMemo(() => {
      return selected
         ? styles.selectedImage
         : disabled
         ? styles.disabledImage
         : ''
   }, [selected, disabled])

   return (
      <article
         className={`${styles.matchedImage} ${stateClass}`}
         onClick={() => onClick(image)}
      >
         <div className={styles.checkBox}>
            <Svg id="tick" fill="white" />
         </div>
         <picture>
            <source srcSet={src} type="image/webp" />
            <source srcSet={src} type="image/png" />
            <source srcSet={src} type="image/jpeg" />
            <source srcSet={src} type="image/svg+xml" />
            <img
               alt={`Matched Image ${Position + 1}`}
               src={src}
               loading="lazy"
            />
         </picture>
         <div className={styles.icon}>
            <Svg id="matchedImage" fill="white" />
         </div>
      </article>
   )
}

export default memo(MatchedImage)
