import classNames from 'classnames'
import { Link } from 'gatsby'
import React from 'react'
import * as styles from './navItem.module.scss'
import Svg from '../../svg/svg'

const FailureLogout = () => (
   <li className={classNames(styles.navItem, styles.navItemPrimary)}>
      <Link className={styles.navItemLink} to="/logout/">
         <span className={styles.navItemIcon}>
            <Svg id="signout" height={17} />
         </span>
         Sign Out
      </Link>
   </li>
)

export { FailureLogout }
