import React, { memo } from 'react'

import svgLogo from '../../../assets/images/logo-yellow.svg'

import oldLogo from '../../../assets/images/shareMyToolbox_Web_Logo.svg'

const Logo = ({ width = '180px', useNewLogo = false }: LogoProps) => (
   <img
      style={{ width }}
      src={useNewLogo ? svgLogo : oldLogo}
      alt="ShareMyToolbox"
   />
)

export default memo(Logo)
