import React, { memo } from 'react'
import { BiColumns } from 'react-icons/bi'
import { Drawer, ItemReorder, SlidePanel } from '..'
import { ItemReorderPanelProps } from './types'
import { biColumnsStyle, permissions } from './constants'

const ItemReorderPanel = ({
   isColumnSettingsOpen,
   setIsColumnSettingsOpen,
   handleResetColumns,
   handleSaveColumnReorder,
   handleReorderColumns,
   defaultColumnItems,
   currentColumnItems,
   tableOptions,
}: ItemReorderPanelProps) => (
   <SlidePanel
      isOpen={isColumnSettingsOpen}
      onClose={() => setIsColumnSettingsOpen(false)}
   >
      <Drawer
         heading="Table Columns"
         icon={<BiColumns style={biColumnsStyle} />}
         onReset={handleResetColumns}
         onSave={handleSaveColumnReorder}
      >
         <p>
            Add or remove columns. To change the column order, drag and drop a
            field.
         </p>
         <ItemReorder
            defaultItems={defaultColumnItems}
            items={
               tableOptions?.length ? currentColumnItems : defaultColumnItems
            }
            onChange={handleReorderColumns}
            permissions={permissions ?? {}}
         />
      </Drawer>
   </SlidePanel>
)

export default memo(ItemReorderPanel)
