import React, { memo, useMemo, useState, MouseEvent, useCallback } from 'react'
import { PieChart as RMPieChart } from 'react-minimal-pie-chart'

import * as styles from './pieChart.module.scss'
import { PieChartProps } from './types'
import { Tooltip } from '..'
import { TooltipPlacement } from '../tooltip/types'
import { getRandomTooltipPlacement } from '../tooltip/helpers'
import classNames from 'classnames'

const PieChart = ({
   segments,
   onSegmentClick,
   showTooltips,
   compactVariant,
}: PieChartProps) => {
   const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)
   const [tooltipContent, setTooltipContent] = useState<string>('')
   const [tooltipPlacement, setTooltipPlacement] =
      useState<TooltipPlacement>('top-start')

   const segmentsStyle = useMemo(
      () => ({
         cursor: onSegmentClick ? 'pointer' : 'default',
      }),
      [onSegmentClick]
   )

   const onClick = useCallback(
      (e: MouseEvent, segmentIndex: number) =>
         onSegmentClick
            ? onSegmentClick(e, segmentIndex)
            : onMouseOver(e, segmentIndex),
      [onSegmentClick]
   )

   const onMouseOver = useCallback(
      (_e: MouseEvent, segmentIndex: number) => {
         const tooltipText = segments[segmentIndex].label
         const placement = getRandomTooltipPlacement(['top-start', 'right-end'])
         setTooltipPlacement(placement)
         setTooltipContent(tooltipText)
         setTooltipVisible(true)
      },
      [showTooltips]
   )

   const onMouseOut = useCallback(
      (_e: MouseEvent, _segmentIndex: number) => {
         setTooltipVisible(false)
         setTooltipContent('')
      },
      [showTooltips]
   )

   const wrapperClassName = classNames(styles.wrapper, {
      [styles.compact]: compactVariant,
   })

   return (
      <figure className={wrapperClassName}>
         <div className={styles.chart}>
            <RMPieChart
               data={segments}
               onClick={onClick}
               onMouseOver={onMouseOver}
               onMouseOut={onMouseOut}
               segmentsStyle={segmentsStyle}
               lineWidth={40}
               animate
            />
            {showTooltips && (
               <Tooltip
                  content={tooltipContent}
                  isVisible={tooltipVisible}
                  setIsVisible={setTooltipVisible}
                  placement={tooltipPlacement}
               />
            )}
         </div>
         <figcaption className={styles.legend}>
            <ul>
               {segments.map((segment, index) => (
                  <li
                     key={segment.label}
                     style={segmentsStyle}
                     onClick={(e) => onClick(e, index)}
                  >
                     <span
                        className={styles.colourBox}
                        style={{ backgroundColor: `${segment.color}` }}
                     ></span>{' '}
                     <p className={styles.label}>{segment.label}</p>
                  </li>
               ))}
            </ul>
         </figcaption>
      </figure>
   )
}

export default memo(PieChart)
