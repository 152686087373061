import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import { useEffect } from 'react'

export default (): UseAuditsTotals => {
   const request = useAPI()

   const { data, isLoading, isSuccess, refetch, isRefetching } = useQuery(
      'audit-totals',
      getAuditTotalsFromAPI
   )

   async function getAuditTotalsFromAPI(): Promise<AxiosResponse<AuditTotals>> {
      return await request.get(PATH.AUDITS.TOTALS)
   }

   useEffect(() => {
      refetch()
   }, [])

   return {
      totals: data?.data,
      isLoading: isLoading || isRefetching,
      isSuccess,
   }
}
