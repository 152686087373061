import React, { memo, useEffect, useState } from 'react'

import { Map, MapPin } from '../../../map/'

import * as styles from './itemGPSMap.module.scss'
import Alert from '../../../alert/alert'
import { Severity } from '../../../../../types'
import { ItemGPSMapProps } from './types'
import { MapCoordinates } from '../../../map/map/types'

const ItemGPSMap = ({
   selectedItem,
   gps_bt_locationsList,
   isLoadingLocationsList,
   selectedLocation,
   selectedLocationToCurrentLocation,
   selectedLocationToScanLocation,
   isInfoCardOpen,
   handleCloseInfoCard,
   handleOpenInfoCard,
   setIsMapZooming,
}: ItemGPSMapProps) => {
   const [hasAssignedLocation, setHasAssignedLocation] =
      useState<boolean>(false)
   const [totalMarkers, setTotalMarkers] = useState<MapCoordinates[]>([])
   const [reverseGeoLookUpResults, setReverseGeoLookupResults] =
      useState<string>('')

   useEffect(() => {
      const scanAndPingMarkers = gps_bt_locationsList.map(
         ({ Latitude, Longitude }) => ({
            lat: Latitude,
            lng: Longitude,
         })
      )

      if (selectedItem?.Latitude && selectedItem?.Longitude) {
         const assignedLocation = {
            lat: selectedItem?.Latitude,
            lng: selectedItem?.Longitude,
         }
         //Add assigned to markers if one exists with lat, lng
         setTotalMarkers([assignedLocation, ...scanAndPingMarkers])
         setHasAssignedLocation(true)
      } else {
         setTotalMarkers([...scanAndPingMarkers])
      }
   }, [selectedItem, gps_bt_locationsList])

   return (
      <div className={styles.mapWrapper}>
         {!isLoadingLocationsList && !totalMarkers.length && (
            <Alert
               message={'No results have been found.'}
               severity={Severity.INFO}
            />
         )}
         {!isLoadingLocationsList && totalMarkers.length > 0 && (
            <Map
               markers={totalMarkers}
               selectedLocation={selectedLocation}
               handleCloseInfoCard={handleCloseInfoCard}
               handleOpenInfoCard={handleOpenInfoCard}
               fitToBounds={false}
               retrieveGeoLookupResults={setReverseGeoLookupResults}
               setIsMapZooming={setIsMapZooming}
            >
               {hasAssignedLocation && (
                  <MapPin
                     key={selectedItem.localUniqueId}
                     lat={selectedItem.Latitude}
                     lng={selectedItem.Longitude}
                     tooltip={selectedItem?.CurrentLocation}
                     displayHighlight={true}
                     onClick={selectedLocationToCurrentLocation}
                     infoCardId={selectedItem.localUniqueId}
                     isInfoCardOpen={isInfoCardOpen}
                     handleCloseInfoCard={handleCloseInfoCard}
                     selectedLocation={selectedLocation}
                     reverseGeoLookUpResults={reverseGeoLookUpResults}
                  />
               )}
               {gps_bt_locationsList.map((location, _index) => (
                  <MapPin
                     svgId="gps"
                     key={location?.ID}
                     lat={location?.Latitude}
                     lng={location?.Longitude}
                     tooltip={location?.CreatedByName}
                     displayHighlight={false}
                     onClick={() => selectedLocationToScanLocation(location)}
                     infoCardId={location?.ID}
                     isInfoCardOpen={isInfoCardOpen}
                     handleCloseInfoCard={handleCloseInfoCard}
                     selectedLocation={selectedLocation}
                     reverseGeoLookUpResults={reverseGeoLookUpResults}
                  />
               ))}
            </Map>
         )}
      </div>
   )
}

export default memo(ItemGPSMap)
