import React, { memo, useMemo } from 'react'

import * as styles from './footer.module.scss'
import classNames from 'classnames'

const APP_COMPANY = process.env.APP_COMPANY

const Footer = ({ wrap, whiteText }: FooterProps) => {
   const year = useMemo(() => new Date().getFullYear(), [])

   const footerClassName = useMemo(
      () =>
         classNames(styles.footer, {
            [styles.footerWrap]: wrap,
            [styles.whiteText]: whiteText,
         }),
      [wrap, whiteText]
   )

   return (
      <footer className={footerClassName}>
         <p className={styles.topText}>Smarter Tool Management</p>
         <p className={styles.bottomText}>
            Copyright &#169;{year} {APP_COMPANY}
         </p>
      </footer>
   )
}

export default memo(Footer)
