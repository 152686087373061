import React, { ReactNode } from 'react'
import classNames from 'classnames'

import { FieldError } from '..'
import * as styles from './fieldWrapper.module.scss'

interface Props {
   children?: ReactNode
   className?: string
   description?: string
   insetDescription?: boolean
   disabled?: boolean
   error?: string
   hasFocus?: boolean
   id: string
   label?: string
   noMargin?: boolean
   required?: boolean
}

const FieldWrapper: React.FC<Props> = (props) => {
   const {
      children,
      className,
      description,
      insetDescription,
      disabled = false,
      error,
      hasFocus,
      id,
      label,
      noMargin = false,
      required,
   } = props

   return (
      <div
         className={classNames(
            styles.fieldWrapper,
            {
               [styles.fieldWrapperError]: !!error,
               [styles.fieldWrapperNoMargin]: noMargin,
               [styles.fieldWrapperNoLabel]: !label,
               [styles.fieldWrapperDisabled]: disabled,
            },
            className
         )}
      >
         <label
            htmlFor={id}
            className={classNames(styles.fieldLabel, {
               [styles.fieldLabelFocussed]: hasFocus,
               [styles.fieldLabelHidden]: !label,
            })}
         >
            {label || id}
            {required && ' *'}
         </label>
         {children}
         {error && <FieldError text={error} />}
         {description && (
            <div
               className={
                  !insetDescription
                     ? styles.fieldDescription
                     : styles.fieldDescriptionInset
               }
            >
               {description}
            </div>
         )}
      </div>
   )
}

export default FieldWrapper
