import React, { memo, MouseEvent, useCallback, useMemo } from 'react'
import * as styles from './imageCard.module.scss'
import { ImageCardProps } from './types'
import Svg from '../svg/svg'

const ImageCard = ({
   src,
   alt,
   isPrimaryImage,
   isMatchedImage,
   onImageClick,
   onDeleteClick,
}: ImageCardProps) => {
   const handleDeleteClick = useCallback(
      (e: MouseEvent<HTMLDivElement>) => {
         e.stopPropagation()
         onDeleteClick()
      },
      [onDeleteClick]
   )

   const stateClass = useMemo(
      () => (isPrimaryImage ? styles.greenBorder : ''),
      [isPrimaryImage]
   )

   return (
      <article
         className={`${styles.imageCard} ${stateClass}`}
         onClick={onImageClick ? onImageClick : null}
      >
         {onDeleteClick && (
            <div className={styles.closeButton} onClick={handleDeleteClick}>
               <Svg
                  id="close"
                  overrideViewBox="0,0,8,8"
                  height="22"
                  width="22"
               />
            </div>
         )}
         <picture>
            <source srcSet={src} type="image/webp" />
            <source srcSet={src} type="image/png" />
            <source srcSet={src} type="image/jpeg" />
            <source srcSet={src} type="image/svg+xml" />
            <img alt={alt} src={src} width={100} height={100} />
         </picture>
         {isMatchedImage && (
            <div className={styles.icon}>
               <Svg id="matchedImage" fill="white" />
            </div>
         )}
      </article>
   )
}

export default memo(ImageCard)
